import "../Styles/index.scss";
import { Fancybox } from "@fancyapps/ui";
import retag from "svelte-retag";
import { initializeMaps } from "@wagich/bluc-map";

Fancybox.bind(".asset.is-zoomable", {
	groupAttr: "data-gallery",
});
Fancybox.bind("[data-fancybox]");

initializeMaps();

let isNavOpen: boolean = false;
document.documentElement.addEventListener("click", e => {
	let target = e.target! as HTMLElement;

	if (isNavOpen && target.closest(".nav-overlay") == null) {
		document.documentElement.classList.remove("is-nav-open")
		isNavOpen = false;
		return;
	}

	if (target.closest(".nav-toggle") != null) {
		isNavOpen = document.documentElement.classList.toggle("is-nav-open");
	}
});

for (let element of document.querySelectorAll(".orb-block__trigger")) {
	element.addEventListener("click", () => {
		let scrollX = (element.closest(".content-block")?.nextElementSibling as HTMLElement)?.offsetTop;
		if (scrollX != null) {
			window.scrollTo({
				behavior: "smooth",
				top: scrollX
			});
		}
	});
}

if (document.querySelector("nomination-form") != null) {
	import("./components/nomination-form.svelte").then(m => {
		retag({
			component: m.default,
			tagname: "nomination-form",
		});
	})
}

if (document.querySelector("event-registration") != null) {
	import("./components/event-registration.svelte").then(m => {
		retag({
			component: m.default,
			tagname: "event-registration",
		});
	})
}

if (document.querySelector("cast-ballot") != null) {
	import("./live-voting/cast-ballot.svelte").then(m => {
		retag({
			component: m.default,
			tagname: "cast-ballot",
		});
	})
}

if (document.querySelector("contest-results") != null) {
	import("./live-voting/contest-results.svelte").then(m => {
		retag({
			component: m.default,
			tagname: "contest-results",
		});
	})
}
